<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Productos',

    metaInfo: {
      title: 'Productos',
      meta: [
        { name: 'description', content: 'Desde el pudin de pez roca y Txangurro, hasta nuestras especialidades gourmet, toda una exquisitez' },
        { name: 'keywords', content: 'Productos,Tarrinas,pudin,txangurro' },
      ],
    },

    extends: View,

    mixins: [
      LoadSections([
        'portada',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'about',
      },
    },
  }
</script>
